<template>
  <div id="login-container" class="single-card">
    <div id="login-form-card" class="dx-card">
      <dx-validation-group>
        <div class="login-header">
          <div>
            <img src="@/assets/images/prm_logo_long.jpg" width="100%" />
          </div>
          <div class="title">{{ title }}</div>
        </div>
        <div class="dx-field" style="display: flex; align-items: center">
          <dx-text-box
            :placeholder="$t('login.hint_username')"
            width="80%"
            :value.sync="login"
            style="margin-left: auto; margin-right: auto"
            :disabled="isLoading"
          >
            <dx-validator>
              <dx-required-rule :message="$t('login.err_username_required')" />
            </dx-validator>
          </dx-text-box>
        </div>

        <div class="dx-field" style="display: flex; align-items: center">
          <dx-text-box
            :placeholder="$t('login.hint_password')"
            width="80%"
            mode="password"
            :value.sync="password"
            style="margin-left: auto; margin-right: auto"
            :disabled="isLoading"
          >
            <dx-validator>
              <dx-required-rule :message="$t('login.err_password_required')" />
            </dx-validator>
          </dx-text-box>
        </div>

        <div class="dx-field" style="display: flex; align-items: center">
          <dx-check-box
            style="color: white; margin-left: auto; margin-right: auto"
            width="80%"
            :value.sync="rememberUser"
            :text="$t('login.lbl_remember_me')"
            :disabled="isLoading"
          />
        </div>

        <div class="dx-field" style="display: flex; align-items: center">
          <dx-button
            id="button"
            type="success"
            @click="onLoginClick"
            width="80%"
            height="42"
            style="margin-left: auto; margin-right: auto"
          >
            <template #content>
              <span>
                <DxLoadIndicator
                  :visible="isLoading"
                  class="button-indicator"
                  shading-color="rgba(0,255,0,0.3)"
                />
                <span
                  class="dx-button-text"
                  style="margin-top: auto; margin-bottom: auto"
                  >{{ $t("login.btn_login") }}</span
                >
              </span>
            </template>
          </dx-button>
        </div>

        <LanguageSelectSimple
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2em;
          "
        />
      </dx-validation-group>
    </div>
  </div>
</template>

<script>
import LanguageSelectSimple from "../components/language-select-simple";

import DxButton from "devextreme-vue/button";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxCheckBox from "devextreme-vue/check-box";
import DxTextBox from "devextreme-vue/text-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import notify from "devextreme/ui/notify";
import CryptoJS from "crypto-js";

import auth from "../auth";
import util from "../commons/utility";

export default {
  created() {
    let errorMessage = this.$route.query.errorMessage;

    if (
      errorMessage !== undefined &&
      errorMessage !== null &&
      errorMessage.length > 0
    ) {
      notify(errorMessage, "error");
    }
  },
  mounted() {
    let userName = localStorage.getItem("SAVED_USER_NAME");
    let passEncripted = localStorage.getItem("SAVED_PASSWORD");
    let rememberUser =
      userName !== undefined &&
      userName !== null &&
      passEncripted !== undefined &&
      passEncripted !== null;

    if (rememberUser) {
      let bytePassword = CryptoJS.AES.decrypt(passEncripted, auth.key);

      this.login = userName;
      this.password = bytePassword.toString(CryptoJS.enc.Utf8);
    }

    this.rememberUser = rememberUser;
  },
  data() {
    return {
      isLoading: false,
      loginButtonText: "LOGIN",
      title: this.$appInfo.title,
      login: "",
      password: "",
      rememberUser: false,
    };
  },
  methods: {
    onLoginClick(e) {
      if (this.isLoading) return;

      if (!e.validationGroup.validate().isValid) {
        return;
      }

      this.setLoading(true);

      auth
        .logIn(this.login, this.password, this.$http, null)
        .then((response) => {
          this.setLoading(false);
          // check if tenant id exist
          let accessToken = response.data.access_token;
          let tenantId = response.data.tenantid;

          auth.saveUserData(
            this.login,
            this.login,
            this.password,
            response.data.access_token,
            this.rememberUser
          );
          util.saveTempUserData(this.login, this.password, tenantId);

          this.$router
            .push(this.$route.query.redirect || "/resource-selection")
            .catch(() => {});
        })
        .catch((err) => {
          this.setLoading(false);
          notify(err.message, "error", 2000);
        });
    },
    setLoading(isLoading) {
      this.loginButtonText = isLoading ? "Logging in" : "LOGIN";
      this.isLoading = isLoading;
    },
  },
  components: {
    DxButton,
    DxCheckBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationGroup,
    DxLoadIndicator,
    LanguageSelectSimple,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.login-header {
  text-align: center;
  margin-bottom: 20px;

  .title {
    color: white;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
  }
}

.font-white {
  color: white;
}

@keyframes slideleft {
  from {
    background-position: 0%;
  }
  to {
    background-position: 90000%;
  }
}

@-webkit-keyframes slideleft {
  from {
    background-position: 0%;
  }
  to {
    background-position: 90000%;
  }
}

#login-container {
  background-image: url("../assets/images/bg.jpg");
  background-repeat: repeat-x;
  animation: slideleft 20000s infinite linear;
  -webkit-animation: slideleft 20000s infinite linear;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-attachment: fixed;
  position: relative;
  min-height: 100vh;
}

#login-form-card {
  background: transparent;
  border: 0px;
}

#button .dx-button-content {
  padding: 0;
}

#button .button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.single-card {
  .dx-card {
    width: 330px;
    margin: auto auto;
    padding: 40px;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }
  }
}
</style>
